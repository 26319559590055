import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAvailabilitySchemes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('appointment/availability-schemes', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAvailabilityScheme(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`appointment/availability-schemes/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteAvailabilityScheme(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`appointment/availability-schemes/${id}/delete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
