<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title">
              {{ activity.typeName }}
            </h1>
            <p class="log_info">
              Created by {{ activity.createdBy ? activity.createdBy.name : '' }} on {{ dateFormatWithTime(activity.createdAt) }} <br>Last updated on {{ dateFormatWithTime(activity.updatedAt) }}
            </p>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right d-md-block d-none mb-1"
        md="3"
        cols="12"
      >
        <div class="user_block ac_type">
          <div class="user_avatar" />
          <div class="user_info">
            <h6>Status</h6>
            <h5>{{ activity.status }}</h5>
          </div>
          <div class="action_button">
            <b-button
              v-b-modal.modal-update-status
              variant="flat-primary"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
                size="16"
              />
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- Table Container Card -->

    <b-card
      class="mb-3 px-3 py-2 calllog-details"
    >
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="8"
        >
          <h3>Activity Type Details</h3>
        </b-col>

        <b-col
          class="content-header-right text-md-right d-md-block d-none mb-1"
          md="4"
          cols="12"
        >
          <b-button
            variant="flat-primary"
            :to="{ name: 'appointment-activity-types-edit', params: { id: $route.params.id } }"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th>
              Type ID
            </b-th>
            <b-td class="mt-50">
              {{ activity.typeID }}
            </b-td>
            <b-th>
              Show in Portal
            </b-th>
            <b-td>{{ activity.customerBookable === true ? 'Yes' : 'No' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Activity Type
            </b-th>
            <b-td>
              <b-badge
                :variant="`${activity.color}`"
                class="small-badge-button small-badge-button-activity-list"
                :style="{ '--bg-color': activity.color }"
              >
                {{ activity.typeName }}
              </b-badge>
            </b-td>
            <b-th>
              Customers Only
            </b-th>
            <b-td>{{ activity.prospectBookable === true ? 'Yes' : 'No' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Chinese Title
            </b-th>
            <b-td class="mt-50">
              {{ activity.typeNameSG }}
            </b-td>
            <b-th>
              Location
            </b-th>
            <b-td class="mt-50">
              {{ activity.location }}
              <br>
              {{ activity.locationSG }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Feature Image
            </b-th>
            <b-td>
              <div class="clearfix">
                <b-img
                  v-for="(url, key) in activity.imageURLs"
                  :key="key"
                  class="mb-1 mb-sm-0"
                  height="120"
                  :src="url"
                />
              </div>
            </b-td>

            <b-th>
              Timeslot Booking Title
            </b-th>
            <b-td class="mt-50">
              {{ activity.timeSlotTitleSG }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <hr class="dividerHR hrDbMR">
      <availability-scheme-list />
    </b-card>

    <b-card
      class="mb-3 px-3 py-2 calllog-details custom_fields"
    >
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="9"
        >
          <h3>Custom Form</h3>
        </b-col>

        <b-col
          class="content-header-right text-md-right d-md-block d-none mb-1"
          md="3"
          cols="12"
        >
          <b-button
            variant="flat-primary"
            :to="{ name: 'appointment-activity-types-edit', params: { id: $route.params.id } }"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
        </b-col>
      </b-row>
      <div
        v-for="(opt, key) in activity.customFields"
        :key="key"
      >
        <b-row
          class="content-header mr-50"
        >
          <!-- Content Left -->
          <b-col
            class="content-header-left"
            cols="12"
            md="2"
          >
            <p class="font-medium">
              Component Type
            </p>
          </b-col>
          <b-col
            class="content-header-left"
            cols="12"
            md="10"
          >
            <p>{{ resolveOptionValue(opt.type) }}</p>
          </b-col>
        </b-row>
        <div
          v-if="opt.type == 'file'"
        >
          <b-row
            class="content-header mr-50"
          >
            <b-col
              class="content-header-left"
              cols="12"
              md="2"
            >
              <p class="font-medium">
                Uploaded File
              </p>
            </b-col>
            <b-col
              class="content-header-left"
              cols="12"
              md="10"
            >
              <b-img
                thumbnail
                fluid
                width="200px"
                height="auto"
                :src="opt.file"
              />
            </b-col>
          </b-row>
        </div>
        <div
          v-if="opt.type == 'title-and-text'"
        >
          <b-row
            class="content-header mr-50"
          >
            <!-- Content Left -->
            <b-col
              class="content-header-left"
              cols="12"
              md="2"
            >
              <p class="font-medium">
                Title
              </p>
            </b-col>
            <b-col
              class="content-header-left"
              cols="12"
              md="10"
            >
              <p>{{ opt.label }}</p>
            </b-col>
          </b-row>
          <b-row
            class="content-header mr-50"
          >
            <!-- Content Left -->
            <b-col
              class="content-header-left"
              cols="12"
              md="2"
            >
              <p class="font-medium">
                Text
              </p>
            </b-col>
            <b-col
              class="content-header-left"
              cols="12"
              md="10"
            >
              <p>{{ opt.description ? opt.description : '-' }}</p>
            </b-col>
          </b-row>
        </div>
        <div
          v-if="opt.type == 'short-answer' || opt.type == 'long-answer' || opt.type == 'single-select' || opt.type == 'multi-select'"
        >
          <b-row
            class="content-header mr-50"
          >
            <!-- Content Left -->
            <b-col
              class="content-header-left"
              cols="12"
              md="2"
            >
              <p class="font-medium">
                Label
              </p>
            </b-col>
            <b-col
              class="content-header-left"
              cols="12"
              md="10"
            >
              <p>{{ opt.label }}</p>
            </b-col>
          </b-row>
          <b-row
            class="content-header mr-50"
          >
            <!-- Content Left -->
            <b-col
              class="content-header-left"
              cols="12"
              md="2"
            >
              <p class="font-medium">
                Description
              </p>
            </b-col>
            <b-col
              class="content-header-left"
              cols="12"
              md="10"
            >
              <p>{{ opt.description ? opt.description : '-' }}</p>
            </b-col>
          </b-row>
          <b-row
            v-if="opt.extraOptions.length > 0"
            class="content-header mr-50"
          >
            <!-- Content Left -->
            <b-col
              class="content-header-left"
              cols="12"
              md="2"
            >
              <p class="font-medium">
                Add Selection Options
              </p>
            </b-col>
            <b-col
              class="content-header-left"
              cols="12"
              md="10"
            >
              <span
                v-for="(option, index) in opt.extraOptions"
                :key="index"
              >
                {{ option.key }} <span v-if="index + 1 != opt.extraOptions.length">, </span>
              </span>
            </b-col>
          </b-row>
          <b-row
            class="content-header mr-50"
          >
            <!-- Content Left -->
            <b-col
              class="content-header-left"
              cols="12"
              md="2"
            >
              <p class="font-medium">
                Required?
              </p>
            </b-col>
            <b-col
              class="content-header-left"
              cols="12"
              md="10"
            >
              <p>{{ opt.required === 'yes' ? 'Yes' : 'No' }}</p>
            </b-col>
          </b-row>
        </div>
        <b-row
          v-if="key + 1 != activity.customFields.length"
          class="content-header mr-50"
        >
          <b-col
            class="content-header-left"
            cols="12"
          >
            <hr class="dividerHR">
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-modal
      id="modal-update-status"
      ref="update-status-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Status"
      @ok="submitForm"
    >
      <b-form @submit.prevent="submitForm">
        <validation-observer
          ref="activityTypeStatusEditForm"
        >
          <b-form-group
            label="Status*"
            label-for="h-activity-type-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-activity-type-status"
                v-model="status"
                label="title"
                :options="statusDataOptions"
                :reduce="title => title.code"
                :clearable="false"
                @input="statusValidation == true ? statusValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="statusValidation"
                class="text-danger"
              >
                {{ statusError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BButton, BCol, BTableSimple, BTbody, BTr, BTh, BTd, BImg, BBadge, BModal, BForm, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AvailabilitySchemeList from '../availability-schemes/AvailabilitySchemeList.vue'

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BImg,
    BBadge,
    BModal,
    BForm,
    BFormGroup,

    vSelect,

    AvailabilitySchemeList,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      activity: {},
      activityID: '',
      typeID: '',
      name: '',
      status: 'published',
      statusValidation: false,
      statusError: 'Valid status is required',
      statusDataOptions: [
        { title: 'Published', code: 'published' },
        { title: 'Unpublished', code: 'unpublished' },
        { title: 'Set As Draft', code: 'draft' },
      ],

      required,
    }
  },
  created() {
    this.$http.get(`appointment/activity-types/${this.$route.params.id}/show`)
      .then(response => {
        this.activity = response.data || {}
        this.setData()
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  methods: {
    setData() {
      this.activityID = this.activity._id
      this.status = this.activity.status
      this.typeID = this.activity.typeID
      this.name = this.activity.typeName
    },
    submitForm() {
      this.$refs.activityTypeStatusEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.status)

          this.$http.patch(`appointment/activity-types/${this.activityID}/status/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.activity.status = response.data.data.status || 'published'
              this.setData()

              this.$swal({
                title: 'Status Updated!',
                html: `The status for <strong>${this.typeID} ‘${this.name}’</strong> has been updated`,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    resolveOptionValue(val) {
      if (val === 'file') return 'Image'
      if (val === 'title-and-text') return 'Title and Text'
      if (val === 'short-answer') return 'Short Answer Field'
      if (val === 'long-answer') return 'Long Answer Field'
      if (val === 'multi-select') return 'Selection List Field (Add-Ons)'
      if (val === 'single-select') return 'Single Checkbox Field'
      return '-'
    },
  },
}
</script>
