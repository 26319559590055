<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h3 class="custom-header-title float-left pr-1 mb-0">
              Availability Schemes
            </h3>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right d-md-block d-none mb-1"
        md="3"
        cols="12"
      >
        <b-button
          variant="flat-primary"
          :to="{ name: 'appointment-availability-scheme-create', params: { parent: $route.params.id } }"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
            size="16"
          />
          <span class="align-middle">Create New Availability Scheme</span>
        </b-button>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2 count_info">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refAvailabilitySchemesTable"
        class="position-relative has_padding"
        :items="fetchAvailabilitySchemes"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Role -->
        <template #cell(schemeID)="data">
          <div class="text-nowrap">
            <span class="text-bold-black">{{ data.item.schemeID }}</span>
          </div>
        </template>

        <template #cell(operatingHours)="data">
          <div class="text-nowrap">
            <span class="">{{ dateFormatFromTimeStringWithoutTimezone(data.item.startTime) }} - {{ dateFormatFromTimeStringWithoutTimezone(data.item.endTime) }}</span>
          </div>
        </template>

        <template #cell(applicableDays)="data">
          <div class="text-nowrap">
            <span
              v-for="(day, index) in data.item.applicableDays"
              :key="index"
            >
              {{ day }} <span v-if="index + 1 != data.item.applicableDays.length">, </span>
            </span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveAvailabilitySchemeStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template
          v-if="canViewThisAction('update', 'AvailabilityScheme') || canViewThisAction('delete', 'AvailabilityScheme')"
          #cell(actions)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="canViewThisAction('update', 'AvailabilityScheme')"
              :to="{ name: 'appointment-availability-schemes-edit', params: { parent: $route.params.id, id: data.item._id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('delete', 'AvailabilityScheme')"
              @click="deleteEntity(data.item._id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
    </b-card>

    <div
      v-if="totalAvailabilitySchemes > perPage"
      class="mx-2 mb-2"
    >
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center pagination-content"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalAvailabilitySchemes"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            align="center"
            prev-text="Prev"
            next-text="Next"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
              <span>Prev</span>
            </template>
            <template #next-text>
              <span>Next</span>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCard, BRow, BButton, BCol, BTable,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import useAvailabilitySchemeList from './useAvailabilitySchemeList'
import availabilitySchemeStoreModule from './availabilitySchemeStoreModule'

export default {
  components: {
    // UserListAddNew,

    BCard,
    BRow,
    BButton,
    BCol,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },

  methods: {
    deleteEntity(id) {
      this.$swal({
        title: 'Are You Sure?',
        html: 'Selecting Delete will <strong>permanently delete</strong> this item. This action cannot be undone.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Delete!',
        customClass: {
          confirmButton: 'btn btn-danger ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.toDeletedID = id
            this.deleteAvailabilityScheme()
          }
        })
    },
  },
  setup() {
    const AVAILABILITY_SCHEME_APP_STORE_MODULE_NAME = 'app-availability-schemes'

    // console.log(props.roleId)

    // Register module
    if (!store.hasModule(AVAILABILITY_SCHEME_APP_STORE_MODULE_NAME)) store.registerModule(AVAILABILITY_SCHEME_APP_STORE_MODULE_NAME, availabilitySchemeStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AVAILABILITY_SCHEME_APP_STORE_MODULE_NAME)) store.unregisterModule(AVAILABILITY_SCHEME_APP_STORE_MODULE_NAME)
    })

    const { canViewThisAction } = useAclUtils()

    const {
      fetchAvailabilitySchemes,
      tableColumns,
      perPage,
      currentPage,
      totalAvailabilitySchemes,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAvailabilitySchemesTable,
      refetchData,

      toDeletedID,
      deleteAvailabilityScheme,
      currentDataLength,

      // UI
      resolveAvailabilitySchemeStatusVariant,

    } = useAvailabilitySchemeList()

    return {

      // Sidebar

      fetchAvailabilitySchemes,
      tableColumns,
      perPage,
      currentPage,
      totalAvailabilitySchemes,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAvailabilitySchemesTable,
      refetchData,
      toDeletedID,
      deleteAvailabilityScheme,
      currentDataLength,

      // Filter
      avatarText,

      // UI
      resolveAvailabilitySchemeStatusVariant,

      canViewThisAction,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
